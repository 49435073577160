import React, { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import Meta from '../components/Meta'
import { listDevices, createDevice, updateDevice, deleteDevice } from '../actions/deviceActions'

const HomeScreen = ({ match, history }) => {
  const keyword = match.params.keyword
  console.log(`keyword=${keyword}`)

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const deviceList = useSelector((state) => state.deviceList)
  const { loading, error, devices, page, pages } = deviceList
  
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  
  // State for new device input values
  const [newIMEI, setNewIMEI] = useState('');
  const [newNote, setNewNote] = useState('');
  
  const [noteUpdateValues, setNoteUpdateValues] = useState({});
  
  const handleNewIMEIInputChange = (imei) => {
    setNewIMEI(imei)
  };
  
  const handleNewNoteInputChange = (note) => {
    setNewNote(note)
  };  
  

  const handleAddClick = (e) => {
    e.preventDefault()
    console.log('dispatch(createDevice({ newIMEI, newNote }))')
    // Dispatch an action to add the new device
    dispatch(createDevice(newIMEI, newNote));

    // Reset input values after adding the device
    setNewIMEI('');
    setNewNote('');
    // Reload the page
    window.location.reload();
  };


  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
    dispatch(listDevices(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])



  const handleNoteInputChange = (_id, imei, note) => {
    // Update the noteUpdateValues state with the new value
    console.log(_id)
    console.log(imei)
    console.log(note)
    setNoteUpdateValues(prevNoteValues => ({
      ...prevNoteValues,
      [_id]: { imei, note },
    }));
    console.log(noteUpdateValues)
  };

  const handleUpdateClick = (e, _id) => {
    e.preventDefault()
    console.log(noteUpdateValues)
    // Retrieve the current value from noteUpdateValues state based on the device id
    const currentValue = noteUpdateValues[_id] ? noteUpdateValues[_id].note : '';
    console.log(`Updating device with id ${_id} and value: ${currentValue}`);
    let deviceUpdate = {
      "_id": _id,
      "imei": noteUpdateValues[_id].imei,
      "note": noteUpdateValues[_id].note
    }
    console.log(`deviceUpdate = ${JSON.stringify(deviceUpdate)}`)
    dispatch(updateDevice(deviceUpdate));
    window.location.reload();
  };

  const handleRemoveClick = (e, id) => {
    e.preventDefault()
    dispatch(deleteDevice(id));
    window.location.reload();    
  };

  return (
    <>
      <h1> Add Devices </h1>
          <Row className="mb-2 font-weight-bold border">
            <Col className="border-right">IMEI</Col>
            <Col>NOTE</Col>
          </Row>
            <Row className="mb-2 font-weight-bold border">
              <Col className="border-right">
                <input key="add_imei"
                  type="text"
                  className="form-control"
                  readOnly={false}
                  placeholder="Add IMEI"
                  onChange={e => handleNewIMEIInputChange(e.target.value)}
                />
              </Col>
              <Col className="border-right">
                <input key="add_note"
                  type="text"
                  className="form-control"
                  readOnly={false}
                  placeholder="Add Note"
                  onChange={e => handleNewNoteInputChange(e.target.value)}
                />
                <Button
                  variant="primary"
                  size="sm"
                  className="mt-2"
                  onClick={e=> handleAddClick(e)}
                >
                  Add
                </Button>
              </Col>
            </Row>           
      <h1>Latest Devices</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row className="mb-2 font-weight-bold border">
            <Col className="border-right">IMEI</Col>
            <Col>NOTE</Col>
          </Row>          
          {devices.map((device) => (
            <Row key={device._id} className="mb-2 font-weight-bold border">
              <Col className="border-right">{device.imei}</Col>
              <Col className="border-right">
                <input
                  type="text"
                  className="form-control"
                  readOnly={false}
                  placeholder={device.note}
                  onChange={e => handleNoteInputChange(device._id, device.imei, e.target.value)}
                />
                <Button
                  variant="primary"
                  size="sm"
                  className="mt-2"
                  onClick={e => handleUpdateClick(e, device._id)}
                >
                  Update
                </Button>
                {' '} {/* Add space between buttons */}                
                <Button
                  variant="danger"
                  size="sm"
                  className="mt-2"
                  onClick={e => handleRemoveClick(e, device._id)}
                >
                  Remove
                </Button>                
              </Col>
            </Row>              
            ))}
          <Paginate
            pages={pages}
            page={page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
  )
}

export default HomeScreen
