import {
  DEVICE_LIST_REQUEST,
  DEVICE_LIST_SUCCESS,
  DEVICE_LIST_FAIL,
  DEVICE_DETAILS_REQUEST,
  DEVICE_DETAILS_SUCCESS,
  DEVICE_DETAILS_FAIL,
  DEVICE_DELETE_REQUEST,
  DEVICE_DELETE_SUCCESS,
  DEVICE_DELETE_FAIL,
  DEVICE_CREATE_RESET,
  DEVICE_CREATE_FAIL,
  DEVICE_CREATE_SUCCESS,
  DEVICE_CREATE_REQUEST,
  DEVICE_UPDATE_REQUEST,
  DEVICE_UPDATE_SUCCESS,
  DEVICE_UPDATE_FAIL,
  DEVICE_UPDATE_RESET,
  DEVICE_CREATE_REVIEW_REQUEST,
  DEVICE_CREATE_REVIEW_SUCCESS,
  DEVICE_CREATE_REVIEW_FAIL,
  DEVICE_CREATE_REVIEW_RESET,
  DEVICE_TOP_REQUEST,
  DEVICE_TOP_SUCCESS,
  DEVICE_TOP_FAIL,
} from '../constants/deviceConstants'

export const deviceListReducer = (state = { devices: [] }, action) => {
  switch (action.type) {
    case DEVICE_LIST_REQUEST:
      return { loading: true, devices: [] }
    case DEVICE_LIST_SUCCESS:
      return {
        loading: false,
        devices: action.payload.devices,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case DEVICE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deviceDetailsReducer = (
  state = { device: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case DEVICE_DETAILS_REQUEST:
      return { ...state, loading: true }
    case DEVICE_DETAILS_SUCCESS:
      return { loading: false, device: action.payload }
    case DEVICE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deviceDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVICE_DELETE_REQUEST:
      return { loading: true }
    case DEVICE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case DEVICE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deviceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVICE_CREATE_REQUEST:
      return { loading: true }
    case DEVICE_CREATE_SUCCESS:
      return { loading: false, success: true, device: action.payload }
    case DEVICE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case DEVICE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const deviceUpdateReducer = (state = { device: {} }, action) => {
  switch (action.type) {
    case DEVICE_UPDATE_REQUEST:
      return { loading: true }
    case DEVICE_UPDATE_SUCCESS:
      return { loading: false, success: true, device: action.payload }
    case DEVICE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case DEVICE_UPDATE_RESET:
      return { device: {} }
    default:
      return state
  }
}

export const deviceReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVICE_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case DEVICE_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case DEVICE_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case DEVICE_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const deviceTopRatedReducer = (state = { devices: [] }, action) => {
  switch (action.type) {
    case DEVICE_TOP_REQUEST:
      return { loading: true, devices: [] }
    case DEVICE_TOP_SUCCESS:
      return { loading: false, devices: action.payload }
    case DEVICE_TOP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
