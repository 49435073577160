import axios from 'axios'
import {
  DEVICE_LIST_REQUEST,
  DEVICE_LIST_SUCCESS,
  DEVICE_LIST_FAIL,
  DEVICE_DETAILS_REQUEST,
  DEVICE_DETAILS_SUCCESS,
  DEVICE_DETAILS_FAIL,
  DEVICE_DELETE_SUCCESS,
  DEVICE_DELETE_REQUEST,
  DEVICE_DELETE_FAIL,
  DEVICE_CREATE_REQUEST,
  DEVICE_CREATE_SUCCESS,
  DEVICE_CREATE_FAIL,
  DEVICE_UPDATE_REQUEST,
  DEVICE_UPDATE_SUCCESS,
  DEVICE_UPDATE_FAIL,
  DEVICE_CREATE_REVIEW_REQUEST,
  DEVICE_CREATE_REVIEW_SUCCESS,
  DEVICE_CREATE_REVIEW_FAIL,
  DEVICE_TOP_REQUEST,
  DEVICE_TOP_SUCCESS,
  DEVICE_TOP_FAIL,
} from '../constants/deviceConstants'
import { logout } from './userActions'

export const listDevices = (keyword = '', pageNumber = '') => async (
  dispatch, getState
) => {
  try {
    dispatch({ type: DEVICE_LIST_REQUEST })
    
    const {
      userLogin: { userInfo },
    } = getState()
    
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }    

    const { data } = await axios.get(
      `https://notifications.femom.net/api/medisante/devices?keyword=${keyword}&pageNumber=${pageNumber}`, config
    )
    console.log(`device data = ${JSON.stringify(data)}`)

    dispatch({
      type: DEVICE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DEVICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createDevice = (newIMEI = '', newNote = '') => async (dispatch, getState) => {
    console.log('Inside createDevice in deviceActions')
    console.log(newIMEI)
    console.log(newNote)
    
  try {
    dispatch({
      type: DEVICE_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log('Inside createDevice in deviceActions')
    console.log(newIMEI)
    console.log(newNote)    

    const { data } = await axios.post(`https://notifications.femom.net/api/medisante/device`, {newIMEI, newNote}, config)

    dispatch({
      type: DEVICE_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: DEVICE_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateDevice = (device) => async (dispatch, getState) => {
  console.log(`Inside updateDevice, device = ${JSON.stringify(device)}`)
  try {
    dispatch({
      type: DEVICE_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `https://notifications.femom.net/api/medisante/device/${device._id}`,
      device,
      config
    )

    dispatch({
      type: DEVICE_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: DEVICE_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: DEVICE_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const deleteDevice = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEVICE_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    console.log(`deleteDevice = ${id}`)
    await axios.delete(`https://notifications.femom.net/api/medisante/device/${id}`, config)

    dispatch({
      type: DEVICE_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: DEVICE_DELETE_FAIL,
      payload: message,
    })
  }
}